import { Entity, MicroMClient } from "@mcurros2/microm";
import { IconMailStar } from "@tabler/icons-react";
import { NewsletterSubscriptionsDef } from "./NewsletterSubscriptionsDef";

export const NewsletterSubscriptionsIcon = IconMailStar;
export const NewsletterSubscriptionsHelpText = '* Subscribe to our early launch news.';

export class NewsletterSubscriptions extends Entity<NewsletterSubscriptionsDef> {

    constructor(client: MicroMClient, parentKeys = {}) {
        super(client, new NewsletterSubscriptionsDef(), parentKeys);
        this.Form = import('./NewsletterSubscriptionsForm').then((module) => module.NewsletterSubscriptionsForm);
        this.Title = "Newsletter";
        this.HelpText = NewsletterSubscriptionsHelpText;
        this.Icon = NewsletterSubscriptionsIcon;
    }
}