import { Group, MantineTheme, Text } from "@mantine/core";
import { CircleFilledIcon } from "@mcurros2/microm";
import { IconCheck } from "@tabler/icons-react";
import { ReactNode } from "react";

export function NewsletterSubscriptionsStandardColumnOverrides(theme: MantineTheme) {
    return {
        // Exported
        4: {
            render: (value: unknown) => (value) ? <Group style={{ gap: '.5rem' }} noWrap>
                <CircleFilledIcon mr="xs" width="1.3rem" icon={<IconCheck size="0.9rem" />} />
                <Text truncate>{value as ReactNode}</Text>
            </Group> : ''
        },


    }
}