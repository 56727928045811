import { DefaultColumns, EntityColumn, EntityColumnFlags, EntityDefinition, EntityView, CommonFlags as c } from "@mcurros2/microm";
import { ACTMarkAsExported } from "./ACTMarkAsExported";
import { ACTUnmarkExported } from "./ACTUnmarkAsExported";
import { NewsletterSubscriptionsStandardColumnOverrides } from "./NewsletterSubscriptionsStandardColumnOverrides";

const columns = () =>
(
    {
        c_newsletter_subscription_id: new EntityColumn<string>({ name: 'c_newsletter_subscription_id', type: 'char', length: 20, flags: c.PKAutonum, prompt: 'Newsletter Subscription Id' }),
        vc_person_name: new EntityColumn<string>({ name: 'vc_person_name', type: 'varchar', length: 255, flags: c.Edit, prompt: 'Name' }),
        vc_person_lastname: new EntityColumn<string>({ name: 'vc_person_lastname', type: 'varchar', length: 255, flags: c.Edit, prompt: 'Lastname' }),
        vc_person_email: new EntityColumn<string>({ name: 'vc_person_email', type: 'varchar', length: 2048, flags: c.Edit, prompt: 'Email' }),
        c_email_queue_id: new EntityColumn<string>({ name: 'c_email_queue_id', type: 'char', length: 20, flags: c.FK | EntityColumnFlags.nullable, prompt: 'Email Queue Id' }),
        c_exported_status_id: new EntityColumn<string>({ name: 'c_exported_status_id', type: 'char', length: 20, flags: c.Edit, prompt: 'Exported Status Id' }),
        vc_newsletter_subscription_ids: new EntityColumn<string[]>({ name: 'vc_newsletter_subscription_ids', type: 'varchar', length: 255, flags: c.Edit, prompt: 'Newsletter Subscription Ids', isArray: true }),
        ...DefaultColumns()
    }
)

const views = () =>
(
    {
        snew_brwStandard: { name: 'snew_brwStandard', keyMappings: { c_newsletter_subscription_id: 0 }, gridColumnsOverrides: NewsletterSubscriptionsStandardColumnOverrides  } as EntityView
    }
)

const procs = () =>
(
    {
        snew_markExported: { name: 'snew_markExported' },
        snew_unmarkExported: { name: 'snew_unmarkExported' }
    }
)

const clientActions = () =>
(
    {
        ACTMarkAsExported: ACTMarkAsExported,
        ACTUnmarkExported: ACTUnmarkExported
    }
)

export class NewsletterSubscriptionsDef extends EntityDefinition {

    columns = columns();
    views = views();
    procs = procs();
    clientActions = clientActions();

    constructor() {
        super('NewsletterSubscriptions');
    }
}