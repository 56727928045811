import { Avatar, Group, Menu, useMantineTheme } from "@mantine/core";
import { OpenFormProps, useOpenForm } from "@mcurros2/microm";
import { IconMoonStars, IconShieldCog, IconSun, IconUserScan } from "@tabler/icons-react";

export interface LoggedInMenuProps {
    loggedInUser?: string,
    initials?: string,
    showBackoffice?: boolean,
    photoURL?: string,
    onLogOut: () => Promise<void>,
    onEditProfile: (openform: (props: OpenFormProps) => Promise<void>) => Promise<void>,
    colorScheme: 'dark' | 'light',
    toggleColorScheme: () => void,
}

export function LoggedInMenu({
    loggedInUser, initials, showBackoffice, onLogOut, photoURL, onEditProfile, colorScheme, toggleColorScheme
}: LoggedInMenuProps) {

    const theme = useMantineTheme();

    const openform = useOpenForm();

    return (
        <Group>
            <Menu withArrow>
                <Menu.Target>
                    <Avatar src={photoURL} alt={loggedInUser} radius="xl" color="red"
                        style={{
                            border: (theme.colorScheme === 'dark') ? `2px solid ${theme.colors.dark[0]}` : `2px solid ${theme.colors.dark[9]}`
                        }}
                    >{photoURL ? undefined : initials}</Avatar>
                </Menu.Target>
                <Menu.Dropdown>
                    <Menu.Item icon={colorScheme === 'dark' ? <IconSun size="1rem" /> : <IconMoonStars size="1rem" />} onClick={() => {
                        toggleColorScheme();
                    }} >
                        {colorScheme === 'dark' ? 'Light mode' : 'Dark mode'}
                    </Menu.Item>
                    {showBackoffice !== true &&
                        <Menu.Item icon={<IconUserScan size="1rem" />} onClick={async () => {
                            await onEditProfile(openform);
                        }} >
                            Edit profile
                        </Menu.Item>
                    }
                    {showBackoffice &&
                        <>
                            <Menu.Divider />
                            <Menu.Item icon={<IconShieldCog size="1rem" />} component="a" href="/backoffice">
                                Backoffice
                            </Menu.Item>
                        </>
                    }
                    <Menu.Divider />
                    <Menu.Item onClick={async () => {
                        await onLogOut();
                    }}>
                        Log out
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
        </Group>
    )
}