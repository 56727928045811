import { Button, Group, Text } from "@mantine/core";
import { ConfirmAndExecutePanel, EntityClientAction, extractArrayFromSelectedRecords } from "@mcurros2/microm";
import { IconAlertCircle } from "@tabler/icons-react";
import { NewsletterSubscriptions } from "./NewsletterSubscriptions";


export const ACTUnmarkExported: EntityClientAction = {
    name: 'ACTUnmarkExported',
    label: 'Unmark as Exported',
    title: <Text fw={700}>Unmark as exported</Text>,
    refreshOnClose: true,
    dontRequireSelection: false,
    onClick: async ({ entity, modal, selectedKeys, element, onClose }) => {
        if (!modal) {
            console.warn('modal context not found');
            return Promise.resolve(false);
        }
        if (!selectedKeys || selectedKeys.length === 0) return Promise.resolve(false);

        const abort_controller = new AbortController();

        const subscriptions = entity as NewsletterSubscriptions;

        // convert record selection to json array
        const enabled = extractArrayFromSelectedRecords<string>(selectedKeys, subscriptions.def.columns.c_newsletter_subscription_id.name);

        const procParms = {
            vc_newsletter_subscription_ids: enabled
        };

        let errors = '';

        if (!procParms.vc_newsletter_subscription_ids) {
            errors = 'The selected records are invalid';
        }

        if (errors) {
            await modal.open({
                modalProps: {
                    title: <Group><IconAlertCircle size="1.5rem" stroke="1.5" /> <Text fw="700">ERROR</Text></Group>,
                },
                focusOnClosed: element,
                content:
                    <>
                        <Text size="sm" mb="xs">
                            {errors}
                        </Text>
                        <Group mt="xs" position="right">
                            <Button onClick={async () => await modal.close()}>Close</Button>
                        </Group>
                    </>
            });

        }
        else {

            await modal.open({
                modalProps: {
                    title: <Text fw="700">Unmark as exported</Text>
                },
                focusOnClosed: element,
                content: <ConfirmAndExecutePanel
                    content={<Text size="sm" mb="xs">You are going to unmark the selected records as exported. Are you sure?</Text>}
                    operation="proc"
                    onCancel={async () => {
                        abort_controller.abort();
                        await modal.close();
                        if (onClose) await onClose(false);
                    }}
                    onOK={
                        async () => {
                            const result = await subscriptions.API.executeProcess(subscriptions.def.procs.snew_unmarkExported, procParms, abort_controller.signal);
                            const success = result.Failed === false;
                            if (success) {
                                await modal.close();
                                if (onClose) await onClose(success);
                            }
                        }
                    }
                />

            });
        }


        return false;
    }
}